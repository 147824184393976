<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          ทำรายการคืนยอดเสีย
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <b-row>
            <b-col cols="md-12">
              <fieldset class="form-group">
                <label >อัพโหลดไฟล์รายชื่อคืนยอดเสีย Excel (.xls/.xlsx)</label>
                <input type="file" id="input_excel" name="input_excel" class="dropify" accept=".xls,.xlsx" data-allowed-file-extensions='["xls", "xlsx"]' v-on:change="upload($event)" />
              </fieldset>
            </b-col>
          </b-row>
          <hr />
          <b-row>
            <b-col cols="md-12">
              <div class="text-right m-b-10">
                <button type="button" class="btn bg-gradient-warning waves-effect waves-light" @click="clearfile()"><feather-icon icon="RotateCcwIcon" class="font-medium-1 mr-1" />รีเฟรช</button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </div>
    </b-card>
    <!-- แถบแรก -->
    <b-card no-body>
      <b-card-header>
        <h4 class="card-title">
          รายการคืนยอดเสีย
        </h4>
      </b-card-header>
      <div class="card-content">
        <b-card-body>
          <b-row>
            <b-col cols="md-6 col-xl-4">
              <b-card no-body class="mb-3 p-1 widget-content bg-gradient-info waves-effect waves-light">
                <div class="widget-content-wrapper text-white">
                  <div class="widget-content-left">
                    <div class="widget-heading">จำนวนยูสทั้งหมด</div>
                    <div class="widget-subheading">วันที่ล่าสุด</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="widget-numbers text-white">{{ func.toNum(total_users) }}</div>
                  </div>
                </div>
              </b-card>
            </b-col>
            <b-col cols="md-6 col-xl-4">
              <b-card no-body class="mb-3 p-1 widget-content bg-gradient-success waves-effect waves-light">
                <div class="widget-content-wrapper text-white">
                  <div class="widget-content-left">
                    <div class="widget-heading">จำนวนเงินทั้งหมด</div>
                    <div class="widget-subheading">วันที่ล่าสุด</div>
                  </div>
                  <div class="widget-content-right">
                    <div class="widget-numbers text-white">{{ func.toMoney(total_refund) }} ฿</div>
                  </div>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <!-- Table Function -->
          <b-row class="mx-auto mb-1">
            <b-col cols="12 col-md-10" class="pr-1 pl-1">
              <div>
                <b-input-group>

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_start_report"> วันที่ (จาก) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_start_report" id="date_start_report" class="form-control text-center" />

                  <b-input-group-prepend>
                    <span class="input-group-text" label="date_end_report"> วันที่ (ถึง) </span>
                  </b-input-group-prepend>
                  <flat-pickr v-model="date_end_report" id="date_end_report" class="form-control text-center" />

                </b-input-group>
              </div>
            </b-col>
            <b-col cols="12 col-md-2">
              <div class="btn-group">
                <b-button @click="fetch()" variant="btn-primary" class="btn bg-gradient-primary waves-effect waves-light"> ค้นหา </b-button>
              </div>
            </b-col>
          </b-row>
          <!-- End Table Function -->
          <!-- Table Header -->
          <b-row>
            <b-col md="3" sm="2" class="my-1">
              <b-form-group class="mb-0">
                <label class="d-inline-block text-sm-left mr-50">แสดง</label>
                <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions" v-on:change="onPageSelect" style="width: 60px"/>
                <label class="d-inline-block text-sm-right ml-50">รายการ</label>
              </b-form-group>
            </b-col>
            <b-col md="5" sm="6" class="my-1" />
            <b-col md="4" sm="4" class="my-1">
              <b-form-group label="ค้นหา :" label-cols-sm="6" label-align-sm="right" label-size="sm" label-for="filterInput" class="mb-0" >
                <b-input-group size="sm">
                  <b-form-input id="filterInput" v-model="filter" v-on:change="onPageSelect" type="search" />
                  <b-input-group-append>
                    <b-button :disabled="!filter" @click="filter = ''"> ล้าง </b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- End Table Header -->
          <!-- Table Body -->
          <b-row>
            <b-col cols="12">
              <b-table hover responsive :fields="fields" :items="items" :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" show-empty>
                <template #empty="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #emptyfiltered="">
                  <div class="align-items-center text-center">ไม่พบข้อมูล</div>
                </template>
                <template #cell(turnover_amount)="data">
                  {{ data.item.type == '1' ? '-' + func.toMoney(data.value || 0) : data.item.type == '3' ? '-' + func.toMoney(data.value || 0) : func.toMoney(data.value || 0) }} ฿
                </template>
                <template #cell(commission_percent)="data">
                  {{ func.toMoney(data.value || 0) }} %
                </template>
                <template #cell(turn)="data">
                  {{ func.toNum(data.value || 0) }} เท่า
                </template>
                <template #cell(sum_amount)="data">
                  {{ func.toMoney(data.value || 0) }} ฿
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- End Table Body -->
          <!-- Table Footer -->
          <b-row>
            <b-col sm="12" md="5">
              <div role="status" aria-live="polite">แสดง {{ func.show_page(perPage, currentPage) }} ถึง {{ func.show_page_to(perPage, currentPage, recordsTotal) }} จาก {{func.toNum(totalRows)}} รายการ (ค้นหา จากทั้งหมด {{perPage}} รายการ)</div>
            </b-col>
            <b-col sm="12" md="7">
              <b-pagination
                v-on:change="onPageChange"
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="end"
                size="sm"
                class="my-0"
                :first-number="true"
                :last-number="true"
              >
                <template #prev-text>‹ ย้อนกลับ</template>
                <template #next-text>ถัดไป ›</template>
              </b-pagination>
            </b-col>
          </b-row>
          <!-- End Table Footer -->
        </b-card-body>
      </div>
    </b-card>
    <!-- สิ้นสุดแถบแรก -->
    <b-modal
      v-model="modal_create"
      centered
      title="คืนยอดเสียอัตโนมัติ"
      ok-only
      ok-title="ตกลง"
      ok-variant=" bg-gradient-primary waves-effect waves-light"
      @ok="ok_refund()"
    >
      <b-row>
        <b-col cols="12">
          <b-table hover responsive :fields="fieldsRefund" :items="dataRefund" show-empty>
            <template #empty="">
              <div class="align-items-center text-center">ไม่พบข้อมูล</div>
            </template>
            <template #emptyfiltered="">
              <div class="align-items-center text-center">ไม่พบข้อมูล</div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCol, BRow, BTable, BInputGroup, BInputGroupPrepend, BFormInput, BButton, BFormGroup, BInputGroupAppend, BFormSelect,
  BPagination, BDropdown, BDropdownItem, BDropdownDivider,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import func from '@core/comp-functions/misc/func'
import XLSX from '@/assets/plugins/xlsx/xlsx.full.min.js'
import 'dropify/dist/js/dropify.min.js'
import 'dropify/dist/css/dropify.min.css'

export default {
  components: {
    BCard, BCardHeader, BCardBody,
    BCol, BRow, BTable,
    BInputGroup, BInputGroupPrepend, BFormInput, BFormGroup, BInputGroupAppend,
    BButton,
    BFormSelect,
    BPagination,
    BDropdown, BDropdownItem, BDropdownDivider,
    flatPickr,
  },
  data() {
    return {
      func: func,
      BASE_URL: process.env.BASE_URL,
      drEvent: null,
      date_start_report: '',
      date_end_report: '',
      total_users: 0,
      total_refund: 0,
      selected: { title: 'ทั้งหมด' },
      option: [
        { title: 'ทั้งหมด' },
        { title: 'ได้รับยูสเซอร์แล้ว' },
        { title: 'ยังไม่ได้รับยูสเซอร์' },
      ],
      draw: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100, 250, 500, 1000],
      totalRows: 50,
      currentPage: 5,
      recordsTotal: 0,
      modal_create: false,
      dataRefund: [],
      fieldsRefund: [
        { key: 'user', label: 'ยูสเนม', class: 'text-center' },
        { key: 'credit', label: 'คืนยอดเสีย', class: 'text-center' },
      ],
      filter: '',
      filterOn: [],
      fields: [
        { key: 'username', label: 'ยูสเนม', class: 'text-center' },
        { key: 'turnover_amount', label: 'ยอดเล่นเสีย', class: 'text-center' },
        { key: 'commission_percent', label: 'Percent จากยอดเล่นเสีย', class: 'text-center' },
        { key: 'turn', label: 'Turn (x เท่า)', class: 'text-center' },
        { key: 'sum_amount', label: 'จำนวนเงินสุทธิ', class: 'text-center' },
        { key: 'created_at', label: 'วันเวลา', class: 'text-center' },
      ],
      items: [],
      t_fetch: 0,
      bank_list: JSON.parse('{"01":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","02":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","03":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","04":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","05":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","06":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","07":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","08":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","09":"\u0e18.\u0e01.\u0e2a.","1":"\u0e01\u0e23\u0e38\u0e07\u0e40\u0e17\u0e1e","2":"\u0e01\u0e2a\u0e34\u0e01\u0e23\u0e44\u0e17\u0e22","3":"\u0e01\u0e23\u0e38\u0e07\u0e44\u0e17\u0e22","4":"\u0e17\u0e2b\u0e32\u0e23\u0e44\u0e17\u0e22","5":"\u0e44\u0e17\u0e22\u0e1e\u0e32\u0e13\u0e34\u0e0a\u0e22\u0e4c","6":"\u0e01\u0e23\u0e38\u0e07\u0e28\u0e23\u0e35\u0e2d\u0e22\u0e38\u0e18\u0e22\u0e32","7":"\u0e2d\u0e2d\u0e21\u0e2a\u0e34\u0e19","8":"\u0e18\u0e19\u0e0a\u0e32\u0e15\u0e34","9":"\u0e18.\u0e01.\u0e2a."}'),
    }
  },
  mounted() {
    this.totalRows = this.items.length
    this.fetch()
    this.dropify()
    this.t_fetch = setInterval(() => {
      this.fetch()
    }, 1000 * 60)
  },
  watch: {
    filter: {
      handler() {
        this.fetch()
        this.totalRows = this.items.length
        this.currentPage = 1
      },
    }
  },
  methods: {
    dropify() {
      this.drEvent = $(this.$el).find('.dropify').dropify({
        tpl: {
          wrap: '<div class="dropify-wrapper" style="border-radius: 5px;"></div>',
          loader: '<div class="dropify-loader"></div>',
          message: '<div class="dropify-message"><span class="file-icon"></span><br /> {{ default }}</div>',
          preview: '<div class="dropify-preview"><span class="dropify-render"></span><div class="dropify-infos"><div class="dropify-infos-inner"><p class="dropify-infos-message">{{ replace }}</p></div></div></div>',
          filename: '<p class="dropify-filename"><span class="file-icon"></span> <span class="dropify-filename-inner"></span></p>',
          clearButton: '<button type="button" class="dropify-clear">{{ remove }}</button>',
          errorLine: '<p class="dropify-error">ตรวจสอบชนิดไฟล์ให้ถูกต้อง</p>',
          errorsContainer: '<div class="dropify-errors-container"><ul></ul></div>'
        }
      });
    },
    onFiltered(filteredItems) {
      // this.totalRows = filteredItems.length
      // this.currentPage = 1
    },
    onPageSelect() {
      let start = this.perPage * (this.currentPage - 1)
      this.fetch(start)
    },
    onPageChange(page) {
      let start = this.perPage * (page - 1)
      this.fetch(start)
    },
    success(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'success',
        confirmButtonText: 'เรียบร้อย',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    warn(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    error(text='') {
      this.$swal({
        title: 'แจ้งเตือน',
        text: text,
        icon: 'error',
        confirmButtonText: 'ตกลง',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    load(data) {
      if (data == null || data.data == null) return;
      this.draw = data.draw
      this.items = data.data
      this.totalRows = data.recordsFiltered
      this.recordsTotal = data.recordsTotal
      let total = 0
      let users = []
      this.items.forEach( i => {
        if (!users.includes(i.id)) users.push(i.id)
        total += Math.abs(Number(i.sum_amount || 0))
      })
      this.total_users = users.length
      this.total_refund = total
    },
    fetch(start=0) {
      let url = `bonus/returnbalance_list_page?draw=${this.draw}&start=${start}&length=${this.perPage}&search%5Bvalue%5D=${this.filter}&date_start=${this.date_start_report}&date_end=${this.date_end_report}&status=&_=${Date.now()}`;
      this.$http.get(url).then(res => { this.load(res.data) })
    },
    upload(event) {
      this.success('อัพโหลดรายชื่อสำเร็จ');
      let selectedFile = event.target.files[0];
      let fileReader = new FileReader();
          fileReader.readAsBinaryString(selectedFile);
          fileReader.onload = (event) => {
            let data = event.target.result;
            let workbook = XLSX.read(data,{type:"binary"});
            let sheetIndex = 0;
            workbook.SheetNames.forEach(sheet => {
              sheetIndex++;
              if (sheetIndex > 1) return;
              let rowObject = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheet]);
              this.dataRefund = rowObject;//JSON.stringify(rowObject,undefined,4)
            });
            this.modal_create = true;
          }
    },
    clearfile() {
      $('.dropify-clear').trigger('click');
    },
    ok_refund() {
      this.$swal({
        title: 'แจ้งเตือน',
        text: 'ยืนยันการคืนยอดเสีย',
        icon: 'warning',
        confirmButtonText: 'ตกลง',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger mr-2',
        },
        showCancelButton: true,
        reverseButtons: true,
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$swal({
            title: 'กรุณารอสักครู่..',
            showConfirmButton: false,
            allowOutsideClick: false,
            allowEscapeKey: false,
          })
          let data = this.dataRefund.map((d)=>{ 
            return { user: d.user, credit: d.credit };
          });
          this.$swal.showLoading()
          this.$http.post('/bonus/returnbalance/submit', {data}).then(response => { 
            this.$swal.close();
            if (response.data && response.data.code != null && response.data.code === 0) {
              this.success('ทำรายการสำเร็จ');
            } else {
              this.warn(response.data.message);
            }
          }).catch((e) => this.$swal.close());
        }
      })
    },
  },
  beforeRouteLeave (to, from, next) {
    clearInterval(this.t_fetch)
    next()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.widget-content{padding:1rem;flex-direction:row;align-items:center}.widget-content .widget-content-wrapper{display:flex;flex:1;position:relative;align-items:center}.widget-content .widget-content-left .widget-heading{opacity:.8;font-weight:bold}.widget-content .widget-content-left .widget-subheading{opacity:.5}.widget-content .widget-content-right{margin-left:auto}.widget-content .widget-numbers{font-weight:bold;font-size:1.8rem;display:block}.widget-content .widget-content-outer{display:flex;flex:1;flex-direction:column}.widget-content .widget-progress-wrapper{margin-top:1rem}.widget-content .widget-progress-wrapper .progress-sub-label{margin-top:.33333rem;opacity:.5;display:flex;align-content:center;align-items:center}.widget-content .widget-progress-wrapper .progress-sub-label .sub-label-right{margin-left:auto}.widget-content .widget-content-right.widget-content-actions{visibility:hidden;opacity:0;transition:opacity .2s}.widget-content:hover .widget-content-right.widget-content-actions{visibility:visible;opacity:1}
</style>
